<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.working_time") }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="8">
          <select-multiple
            v-model="item.pos_ids"
            :options="posOptions"
            :label="$t('labels.pos')"
            custom-class="c-input-small"
          ></select-multiple>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="item.name"
            class="c-input-small"
            :label="$t('labels.shift')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <time-picker-dialog
            v-model="item.checkin_start"
            :label="$t('labels.checkin_start')"
            :placeholder="$t('labels.checkin_start')"
            :single-line="false"
          ></time-picker-dialog>
        </v-col>
        <v-col cols="4">
          <time-picker-dialog
            v-model="item.start"
            :label="$t('labels.start')"
            :placeholder="$t('labels.start')"
            :single-line="false"
          ></time-picker-dialog>
        </v-col>
        <v-col cols="4">
          <time-picker-dialog
            v-model="item.checkin_end"
            :label="$t('labels.checkin_end')"
            :placeholder="$t('labels.checkin_end')"
            :single-line="false"
          ></time-picker-dialog>
        </v-col>
        <v-col cols="4">
          <time-picker-dialog
            v-model="item.checkout_start"
            :label="$t('labels.checkout_start')"
            :placeholder="$t('labels.checkout_start')"
            :single-line="false"
          ></time-picker-dialog>
        </v-col>
        <v-col cols="4">
          <time-picker-dialog
            v-model="item.end"
            :label="$t('labels.end')"
            :placeholder="$t('labels.end')"
            :single-line="false"
          ></time-picker-dialog>
        </v-col>
        <v-col cols="4">
          <time-picker-dialog
            v-model="item.checkout_end"
            :label="$t('labels.checkout_end')"
            :placeholder="$t('labels.checkout_end')"
            :single-line="false"
          ></time-picker-dialog>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="createWorkingTime">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "WorkingTimeList",
  components: {},
  props: {
    editItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    posOptions: [],
    item: {
      pos_ids: [],
      name: null,
      index: null,
      start: null,
      end: null,
      checkin_start: null,
      checkin_end: null,
      checkout_start: null,
      checkout_end: null,
      status: 1,
    },
    isLoading: false,
  }),
  created() {
    this.init();
  },
  mounted() {
    this.getPos();
  },
  methods: {
    init() {
      if (!this.editItem || !this.editItem.id) {
        return false;
      }
      const pos_ids =
        (this.editItem.pos_ids &&
          this.editItem.pos_ids.split(";").map((i) => +i)) ||
        [];
      this.item = { ...this.editItem, pos_ids };
    },
    cancel() {
      this.$emit("cancel");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    onTimeChange(time) {
      this.item = { ...this.item, start: time };
    },
    async getPos() {
      if (this.idPos) {
        return false;
      }
      const { data } = await httpClient.post("/pos-all-active");
      this.posOptions = data.map((e) => ({
        value: e.id,
        text: e.code,
      }));
    },
    async createWorkingTime() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-save-working-time", this.item);
        this.isLoading = false;
        const msg = this.item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.refreshData();
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
